import axios from "axios";
import { IReceipt } from "@/types/Receipt";
import { Filters } from "@/helpers/Filters";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *Receipt service
 * Provides UI business logic for receipt
 */
export class ReceiptService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IServiceResponse> {
    //console.log("getReceipt:", this.API_URL);
    try {
      const inclStr =
        '/?includes=["Dispatch","Dispatch.Driver","Dispatch.LoadingPlan",' +
        '"Dispatch.LoadingPlan.Warehouse","Dispatch.LoadingPlan.District","Dispatch.LoadingPlan.Transporter"]';
      const result: any = await axios.get(`${this.API_URL}/Receipt${inclStr}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async create(receipt: IReceipt) {
    try {
      const result = await axios.post(`${this.API_URL}/Receipt`, receipt, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
