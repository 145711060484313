
import { Component, Vue } from "vue-property-decorator";
import fab from "vue-fab";
import newLoadingPlanModal from "@/components/LoadingPlanModal.vue";
import formsModal from "@/components/FormsModal.vue";
import newDisasterModal from "@/components/DisasterModal.vue";
import { ILoadingPlan } from "@/types/LoadingPlan";
import { LoadingPlanService } from "@/services/loading-plan-service";
import { ICommodity } from "@/types/Commodity";
import { IWarehouse } from "@/types/Warehouse";
import { ITransporter } from "@/types/Transporter";
import { IDistrict } from "@/types/District";
import { DispatchService } from "@/services/dispatch-service";
import { ReceiptService } from "@/services/receipt-service";
import { IUser } from "@/types/User";
import { Defaults } from "@/helpers/Defaults";
import { ProjectService } from "@/services/project-service";
import { ActivityService } from "@/services/activity-service";
import { IActivity } from "@/types/Activity";
import { Functions } from "@/helpers/Functions";
import { IProject } from "@/types/Project";
import { IRequisition } from "@/types/Requisition";
import { RequisitionService } from "@/services/requisition-service";
import { IInstruction } from "@/types/Instruction";
import { IResponseData } from "@/types/ResponseData";
import { ApiService } from "@/services/api-services";
@Component({
  name: "Home",
  components: {
    formsModal,
    newLoadingPlanModal,
    newDisasterModal,
    fab,
  },
})
export default class Home extends Vue {
  show = { showOverlay: false };
  newRequisitionShow = { showOverlay: false };
  defaults = new Defaults();
  functions = new Functions();
  user: IUser = this.defaults.user;
  apiService = new ApiService();
  loadingPlanService = new LoadingPlanService();
  dispatchService = new DispatchService();
  receiptService = new ReceiptService();
  projectService = new ProjectService();
  activityService = new ActivityService();
  requisitionService = new RequisitionService();
  loadingPlanResponseData: IResponseData = this.defaults.responseData;
  requisitionResponseData: IResponseData = this.defaults.responseData;
  dispatchResponseData: IResponseData = this.defaults.responseData;
  receiptResponseData: IResponseData = this.defaults.responseData;
  commodityResponseData: IResponseData = this.defaults.responseData;
  warehouseResponseData: IResponseData = this.defaults.responseData;
  transporterResponseData: IResponseData = this.defaults.responseData;
  districtResponseData: IResponseData = this.defaults.responseData;
  commodities: ICommodity[] = [];
  warehouses: IWarehouse[] = [];
  transporters: ITransporter[] = [];
  districts: IDistrict[] = [];
  activities: IActivity[] = [];
  projects: IProject[] = [];
  loadingPlan: ILoadingPlan = this.defaults.loadingPlan;
  instruction: IInstruction = this.defaults.instruction;
  filterString = "";
  get dashboardFilters(): {
    time: string;
    district: IDistrict;
    warehouse: IWarehouse;
    transporter: ITransporter;
    status: string;
  } {
    const filters = this.filterString;
    if (filters != null && filters != "{}")
      return JSON.parse(localStorage.getItem("dashboardFilters") || "{}");
    return {
      time: "",
      district: this.defaults.district,
      warehouse: this.defaults.warehouse,
      transporter: this.defaults.transporter,
      status: "",
    };
  }
  get dispatchPercentage(): number {
    return this.loadingPlanResponseData.totalStocks == 0
      ? 0
      : +Math.round(
          ((this.dispatchResponseData.totalStocks / 20) /
            this.loadingPlanResponseData.totalStocks) *
            100
        ).toFixed(2);
  }
  get receiptPercentage(): number {
    return this.dispatchResponseData.totalStocks == 0
      ? 0
      : Math.round(
          (this.receiptResponseData.totalStocks /
            this.dispatchResponseData.totalStocks) *
            100
        );
  }
  get pendingDispatchButton(): string {
    if (this.dispatchResponseData.pendingCount > 0) {
      return "danger";
    }
    return "success";
  }
  get groupedActivities(): any {
    return this.functions.groupBy(this.activities, (i) => i.project.name);
  }
  async initialize(): Promise<void> {
    let requestParams = this.defaults.requestParams;
    requestParams.isPaged = false;
    let response = await this.apiService.getAll("commodity", requestParams);
    if (!response.isSuccess && response.status == 401)
      this.visitRoute("/logout");
    this.commodityResponseData = response.data;
    response = await this.apiService.getAll("district", requestParams);
    this.districtResponseData = response.data;
    response = await this.apiService.getAll("transporter", requestParams);
    this.transporterResponseData = response.data;
    response = await this.apiService.getAll("warehouse", requestParams);
    this.warehouseResponseData = response.data;
    requestParams.isPaged = true;
    response = await this.apiService.getAll("loading plan", requestParams);
    this.loadingPlanResponseData = response.data;
    response = await this.apiService.getAll("receipt", requestParams);
    this.receiptResponseData = response.data;
    response = await this.apiService.getAll("requisition", requestParams);
    this.requisitionResponseData = response.data;
    requestParams.includes =
      '["Driver","LoadingPlan","LoadingPlan.Transporter","LoadingPlan.Warehouse","LoadingPlan.District","LoadingPlan.Commodity"]';
    response = await this.apiService.getAll("dispatch", requestParams);
    this.dispatchResponseData = response.data;
    this.activities = await this.activityService.getAll();
    this.projects = await this.projectService.getAll();
    this.loadingPlan.activity = this.activities[1];
  }
  mounted(): void {
    this.user = JSON.parse(localStorage.getItem("user") || "{}");
    this.filterString = localStorage.getItem("dashboardFilters") || "{}";
  }
  async created(): Promise<void> {
    await this.initialize();
    this.$bvModal.show("spinner-modal");
  }
  visitRoute(link: string): void {
    this.$router.push(link);
  }
  dashboardStatus(val: string): void {
    this.dashboardFilters.status = val;
  }
  async filterDashboard(): Promise<void> {
    localStorage.setItem(
      "dashboardFilters",
      JSON.stringify(this.dashboardFilters)
    );
    this.filterString = JSON.stringify(this.dashboardFilters);
    const loadingPlanResponse = await this.loadingPlanService.getAll();
    if (!loadingPlanResponse.isSuccess && loadingPlanResponse.status == 401)
      this.visitRoute("/logout");
    this.loadingPlanResponseData =
      loadingPlanResponse.data ?? this.defaults.responseData;
    const dispatchResponse = await this.dispatchService.getAll();
    this.dispatchResponseData =
      dispatchResponse.data ?? this.defaults.responseData;
    const receiptResponse = await this.receiptService.getAll();
    this.receiptResponseData =
      receiptResponse.data ?? this.defaults.responseData;
  }
  async clearDashboardFilters(): Promise<void> {
    console.log("removing filters...");
    localStorage.removeItem("dashboardFilters");
    localStorage.removeItem("fLoadingPlanIds");
    this.filterString = "{}";
    const loadingPlanResponse = await this.loadingPlanService.getAll();
    if (!loadingPlanResponse.isSuccess && loadingPlanResponse.status == 401)
      this.visitRoute("/logout");
    this.loadingPlanResponseData =
      loadingPlanResponse.data ?? this.defaults.responseData;
    const dispatchResponse = await this.dispatchService.getAll();
    this.dispatchResponseData =
      dispatchResponse.data ?? this.defaults.responseData;
    const receiptResponse = await this.receiptService.getAll();
    this.receiptResponseData =
      receiptResponse.data ?? this.defaults.responseData;
  }
  sum(items: any[]): any {
    return items.reduce((qty: number, item) => {
      return qty + item.quantity;
    }, 0);
  }
  getVariant(day: string): string {
    return this.dashboardFilters.time == day ? "primary" : "outline-primary";
  }
  async submitNewLoadingPlan(loadingPlan: ILoadingPlan): Promise<void> {
    this.show = { showOverlay: true };
    const result = await this.loadingPlanService.create(loadingPlan);
    console.log(result.status);
    if (!result.isSuccess && result.status == 401) this.visitRoute("/logout");
    this.$bvModal.hide("createLoadingPlanModal");
    let message = "";
    let title = "Creating a new Loading Plan";
    let variant = "danger";
    if (result.isSuccess) {
      message = "New loading plan created successfully";
      variant = "success";
    }
    this.show = { showOverlay: false };
    this.makeToast(message, title, variant);
    const loadingPlanResponse = await this.loadingPlanService.getAll();
    if (!loadingPlanResponse.isSuccess && loadingPlanResponse.status == 401)
      this.visitRoute("/logout");
    this.loadingPlanResponseData = loadingPlanResponse.data;
  }
  async submitNewRequisition(requisition: IRequisition): Promise<void> {
    this.newRequisitionShow = { showOverlay: true };
    const result = await this.requisitionService.create(requisition);
    console.log(result.status);
    if (!result.isSuccess && result.status == 401) this.visitRoute("/logout");
    this.$bvModal.hide("disasterModal");
    let message = result.message;
    let title = "Creating a new Requisition";
    let variant = "danger";
    if (result.isSuccess) {
      message = "New requisition created successfully";
      variant = "success";
    }
    this.show = { showOverlay: false };
    this.makeToast(message, title, variant);
  }
  makeToast(
    message = "Oh Snap! something went wrong. Please try again later.",
    title = "",
    variant = "danger"
  ): void {
    this.$bvToast.toast(message, {
      title: title,
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant,
    });
  }
}
